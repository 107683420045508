import { Grid, IconButton } from '@material-ui/core';
import { Facebook, Instagram } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import configurableVariables from 'configuration';

const Footer = () => {
    const classes = useStyles();

    return (
        <Grid container style={{ justifyContent: 'center', backgroundColor: 'black', color: 'white', paddingTop: '2vh', paddingBottom: '2vh' }}>
            <Grid item xs={12} sm={6}>
                <p style={{ margin: 0, padding: 0, fontWeight: 800, fontSize: '1.5em', fontFamily: '"Montserrat", "sans-serif"', }}>BIG BURGER</p>
                <>
                    <a href={configurableVariables.facebook} target="_blank" rel="noreferrer" className={classes.links}>
                        <IconButton color="secondary" style={{ margin: 0, padding: 0 }}>
                            <Facebook />
                        </IconButton>
                    </a>
                    <a href={configurableVariables.instagram} target="_blank" rel="noreferrer" className={classes.links}>
                        <IconButton color="secondary" style={{ margin: 0, padding: 0 }}>
                            <Instagram />
                        </IconButton>
                    </a>
                </>
            </Grid>
            <Grid item xs={12} sm={6} style={{ fontSize: '0.8em', fontFamily: '"Montserrat", "sans-serif"', fontWeight: 500, color: 'white' }}>
                <ul style={{ margin: 0, listStyle: 'none', paddingLeft: 0 }}>
                    <li>
                        <Link to="/" className={classes.links}>Inicio</Link>
                    </li>
                    <li>
                        <Link to="/Combos" className={classes.links}>Menú</Link>
                    </li>
                    <li>
                        <HashLink to="/#locales" className={classes.links}>Locales</HashLink>
                    </li>
                    <li>
                        <HashLink to="/#contacto" className={classes.links}>Contacto</HashLink>
                    </li>
                    <li>
                        <Link to="/Sumate" className={classes.links}>Sumate</Link>
                    </li>
                </ul>
            </Grid>
        </Grid>
    )
};


const useStyles = makeStyles({
    links: {
        textDecoration: 'none',
        color: 'white',
        fontSize: '1.2em'
    }
});


export default Footer;
