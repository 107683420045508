import Footer from 'components/Footer/Footer';
import { LayoutContent } from 'components/Layout/LayoutContent';
import ComoPedir from '../ComoPedir';
import CafeteriaPage from './Cafeteria';
import PostresComponent from './postres/Menu';

function CafeteriaYPostresPage() {

    return (
        <LayoutContent menu={true}>
            <CafeteriaPage />
            <PostresComponent />
            <ComoPedir />
            <Footer />
        </LayoutContent>
    )
}

export default CafeteriaYPostresPage
