import Footer from 'components/Footer/Footer'
import React from 'react'
import ContactoComponent from './Contacto'
import HomeComponent from './Home'
import LocalesComponent from './Locales'
import MenuComponent from './Menu'
import { LayoutContent } from 'components/Layout/LayoutContent'

const HomePage = (_props: any) => {
    return (
        <LayoutContent>
            <HomeComponent />
            <MenuComponent />
            <LocalesComponent />
            <ContactoComponent />
            <Footer />
        </LayoutContent>
    )
}

export default HomePage
