import { Box, Dialog, IconButton, Toolbar } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import configurableVariables from 'configuration';
import cancel from 'imgs/icons/cancelar.png';
import facebook from 'imgs/icons/Facebook-Logo.png';
import instagram from 'imgs/icons/Instagram-Logo.png';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
const DisplayMobile = ({ menu }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    return (
        <Toolbar>
            <Box display='flex' flexGrow={1}>
                <img src='/logo.png' alt='logo' style={{height: '5vh'}}/>
            </Box>
            <IconButton
                onClick={() => setOpen(true)}
                {...{
                    edge: "start",
                    color: "inherit",
                    "aria-label": "menu",
                    "aria-haspopup": "true",
                }}
                className={classes.iconButton}
            >
                <Menu />
            </IconButton>
            <Dialog fullScreen open={open} onClose={() => setOpen(false)} style={{ zIndex: 9999999999 }}>
                <div className="menu-contenedor">
                    <span style={{ paddingRight: '3em', paddingTop: '3em' }}>
                        <img className="cerrar-boton" onClick={() => setOpen(false)} src={cancel} alt='cerrar' />
                        <ul className="menu">
                            {
                                !menu ?
                                    <>
                                        <li>
                                            <HashLink style={{ textDecoration: "none" }} to="/#home" onClick={() => setOpen(false)}>INICIO</HashLink>
                                        </li>
                                        <li>
                                            <Link style={{ textDecoration: "none" }} to='/Combos'>MENÚ</Link>
                                        </li>
                                        <li>
                                            <HashLink style={{ textDecoration: "none" }} to="/#locales" onClick={() => setOpen(false)}>LOCALES</HashLink>
                                        </li>
                                        <li>
                                            <HashLink style={{ textDecoration: "none" }} to="/#contacto" onClick={() => setOpen(false)}>CONTACTO</HashLink>
                                        </li>
                                        <li>
                                            <Link style={{ textDecoration: "none" }} to='/Sumate' onClick={() => setOpen(false)}>SUMATE</Link>
                                        </li>
                                    </>
                                    :
                                    <>
                                        <li>
                                            <Link style={{ textDecoration: "none" }} to='/'>INICIO</Link>
                                        </li>
                                        <li>
                                            <Link style={{ textDecoration: "none" }} to='/Combos'>COMBOS</Link>
                                        </li>
                                        <li>
                                            <Link style={{ textDecoration: "none" }} to='/Individuales'>INDIVIDUALES</Link>
                                        </li>
                                        <li>
                                            <Link style={{ textDecoration: "none" }} to='/Cafeteria'>CAFETERIA & POSTRES</Link>
                                        </li>
                                        <li>
                                            <HashLink style={{ textDecoration: "none" }} to={`${window.location.pathname}#comoPedir`} onClick={() => setOpen(false)}>¿ CÓMO PEDIR ?</HashLink>
                                        </li>
                                    </>
                            }
                        </ul>
                        <ul className="redes">
                            <li style={{ backgroundImage: `url(${facebook})` }}>
                                <a href={configurableVariables.facebook} target="_blank" rel="noreferrer">Facebook</a>
                            </li>
                            <li style={{ backgroundImage: `url(${instagram})` }}>
                                <a href={configurableVariables.instagram} target="_blank" rel="noreferrer">Instagram</a>
                            </li>
                        </ul>
                    </span>
                </div>
            </Dialog>
        </Toolbar>)
};

const useStyles = makeStyles({
    iconButton: {
        '& svg': {
            fontSize: '10vw'
        }
    }
});


export default DisplayMobile;
