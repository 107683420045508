import Combo1 from 'imgs/postres-menu/EnsaladaFrutas.png';
import Combo2 from 'imgs/postres-menu/Exprimido.png';
import Combo3 from 'imgs/postres-menu/FlanMousseGelatina.png';
import Combo4 from 'imgs/postres-menu/HeladoCuarto.png';
import Combo5 from 'imgs/postres-menu/HeladosCucurucho.png';
import Combo6 from 'imgs/postres-menu/Licuados.png';

const PostresImages = [
    {
        id: 1,
        url: Combo1,
    },
    {
        id: 2,
        url: Combo2,
    },
    {
        id: 3,
        url: Combo3,
    },
    {
        id: 4,
        url: Combo4,
    },
    {
        id: 5,
        url: Combo5,
    },
    {
        id: 6,
        url: Combo6,
    }
];

export default PostresImages;
