import { makeStyles } from '@material-ui/styles';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/fondo-menu-home.png';
import comoPedir from 'imgs/Home/Home-ComoPedir.png';
import burgerHover from 'imgs/icons/Menu-Logo-Hover.png';
import burger from 'imgs/icons/Menu-Logo.png';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

function MenuComponent() {
    const [hoverButton, setHoverButton] = useState(false);
    const { width } = useWindowDimensions();
    const classes = useStyles(width);
    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        return <Redirect to='/Combos' />
    }

    return (
        <div className={classes.carousel}>
            <div className={classes.imgCarousel}>
                <span onClick={() => setRedirect(true)} style={{ cursor: 'pointer' }}>
                    <img className={classes.imgLogo} alt=""
                        onMouseEnter={() => setHoverButton(true)} onMouseLeave={() => setHoverButton(false)}
                        src={hoverButton ? burgerHover : burger} />
                    <h1 style={{ color: "white", fontFamily: '"Montserrat", "sans-serif"', fontWeight: 900 }}>VER MENÚ</h1>
                </span>
                <img src={comoPedir} alt=""/>
            </div>
        </div >
    )
}

const useStyles = makeStyles({
    carousel: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
    },
    comoPedir: {
        //background
        backgroundImage: `url(/comoPedir.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        borderRadius: '20%',
        boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.90)',
        //text
        color: '#ec3237',
        textAlign: 'left'
    },
    imgCarousel: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    logo: {
        borderRadius: '52em',
        padding: '.8em',
        border: '3px solid #EC3237',
        display: 'flex',
        width: 'min(5vw, 200px)',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgLogo: {
        width: 'min(20vw, 100px)',
    }
});

export default MenuComponent
