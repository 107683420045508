import { Grid, makeStyles } from '@material-ui/core';
import Footer from 'components/Footer/Footer';
import { LayoutContent } from 'components/Layout/LayoutContent';
import useWindowDimensions from 'helpers/getDimensions';
import ImagesCombos from 'mock/combosImages';
import React, { useState } from 'react';
import ComoPedir from './ComoPedir';

function MenuPage() {
    const { width } = useWindowDimensions();
    const classes = useStyles();
    const [activeHover, setActiveHover] = useState({ open: false, id: 0 });

    return (
        <LayoutContent menu={true}>
            <div className={classes.carousel}>
                <h1 style={{ fontFamily: '"Montserrat", "sans-serif"', fontWeight: 900, fontSize: '3em' }}>COMBOS</h1>
                <Grid container>
                    {ImagesCombos.map((x, i) => (
                        <Grid item xs={12} sm={6} md={3} key={i} style={{justifyContent: 'center', display: 'flex'}}>
                            <div className={classes.tarjetaContenido} style={width > 960 ? { backgroundImage: `url(${x.url})`, height: "40vh", width: '40vh', backgroundSize: 'contain' } : { backgroundImage: `url(${x.url})`, height: "35vh", width: '35vh', backgroundSize: 'contain', margin: '0 auto', marginBottom: '1vh'  }}
                                onMouseEnter={() => { setActiveHover({ open: true, id: x.id }) }}
                                onMouseLeave={() => { setActiveHover({ open: false, id: 0 }) }}
                            >
                                {activeHover.open && activeHover.id === x.id &&
                                    <div style={{ backgroundColor: "rgb(255 255 255 / 80%)", width: "100%", height: "100%", fontFamily: '"Montserrat", "sans-serif"', textAlign: 'center'}}>
                                        <div className={classes.tarjetaTexto} >
                                            <h3 style={{ fontWeight: 900, marginBottom: ".5em", marginTop: '10%' }}>{x.title.toUpperCase()}</h3>
                                            <p>
                                                <span style={{ display: "block", fontSize: '1.4em', fontWeight: 400, padding: '5%' }}>{x.description}</span>
                                            </p>
                                            <p className={classes.tarjetaPrecio}>{x.price}</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Grid>
                    ))
                    }
                </Grid >
            </div >
            <ComoPedir />
            <Footer />
        </LayoutContent>
    )
}

const useStyles = makeStyles({
    carousel: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#ec3237',
        paddingTop: '10vh',
        color: 'white',
        minHeight: '100vh',
        paddingBottom: '5vh'
    },
    tarjetaContenido: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        margin: '2%',
        backgroundSize: 'cover'
    },
    tarjetaTexto: {
        textAlign: "center",
        marginBottom: "4em",
        color: 'black'
    },
    tarjetaPrecio: {
        fontSize: "1.5em",
        fontWeight: 900,
        color: "#ec3237",
        marginTop: ".8em"
    }
});

export default MenuPage
