import { useEffect, useState } from 'react';
import CafeIcon from 'imgs/icons/Cafe-icono.png';
function MenuMobile({ menu }) {
    const [menuInfo, setMenuInfo] = useState({ firstTable: [], secondTable: [] })

    useEffect(() => {
        const middleIndex = Math.ceil(menu.length / 2)
        let copyOfMenu = [...menu];
        const firstTable = copyOfMenu.splice(0, middleIndex);
        const secondTable = copyOfMenu.splice(-middleIndex)
        setMenuInfo({ firstTable: firstTable, secondTable: secondTable });
    }, [menu])

    return (
        <div className="cafe-contenedor-mobile">
            <div className="cafe-contenido-mobile">
                <span className="cafe-icono-mobile" style={{ backgroundImage: `url(${CafeIcon})` }}>
                </span>
                {Object.keys(menuInfo).map((keyName, i) => {
                    return (
                        <ul className="lista-cafe-mobile" key={i}>
                            {
                                menuInfo[keyName].map((x, index) => {
                                    return (
                                        <li style={{ fontWeight: index % 2 === 1 ? 'bold' : 'normal' }}>{x.title} - {x.price}</li>
                                    )
                                })}
                        </ul>
                    )
                })}
            </div>
        </div >
    )
}

export default MenuMobile;
