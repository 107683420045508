import { Grid, makeStyles } from '@material-ui/core';
import { LocalData } from 'constants/local';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/map.png';
import CardMenu from './Combos/CardMenu';

function ComoPedir() {
    const { width } = useWindowDimensions();
    const classes = useStyles();

    return (
        <div className={classes.carousel} style={{ minHeight: width >= 960 ? '80vh' : '' }} id="comoPedir">
            <div style={{ marginTop: '10vh', marginBottom: '5vh' }}>
                <h1 className={classes.text} style={{ color: '#ec3237' }}>¡ HACÉ TU PEDIDO !</h1>
                <h1 className={classes.text}>EN TU LOCAL MAS</h1>
                <h1 className={classes.text}>CERCANO</h1>
                <p style={{ fontFamily: '"Montserrat", "sans-serif"', fontWeight: 500 }}>¡ SI NO ESTAS DENTRO DEL RADIO DE COBERTURA PODEMOS ACORDAR LA ENTREGA ! </p>
            </div>
            <Grid container>
                <Grid item md={2} xs={12} />
                {LocalData.map((i, x) =>
                    <CardMenu
                        title={ i.location } 
                        link={ i.link } 
                        key={ x }
                        coverageLink={ i.coverageLink } />
                )}
            </Grid>
        </div>
    )
}

const useStyles = makeStyles({
    carousel: {
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        paddingBottom: '5vh'
    },
    text: {
        margin: 0,
        padding: 0,
        color: "#474747",
        fontFamily: '"Montserrat", "sans-serif"',
        fontSize: '3em',
        fontWeight: 900
    }
});

export default ComoPedir
