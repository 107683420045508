import { makeStyles, IconButton } from '@material-ui/core';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/fondo-cafeteria-home.png';
// Imagenes
import CafeteriaImages from 'mock/CafeteriaImages';
import MenuCafeList from 'mock/MenuCafeList';
import MenuDesktop from './menu/menuDesktop';
import MenuMobile from './menu/menuMobile';
import SliderMobile from './SliderMobile';
import { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/components/navigation/navigation.scss';
import 'swiper/swiper.scss';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const useStyles = makeStyles({
    carousel: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '10vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        minHeight: '90vh'
    },
    imgCarousel: {
        width: '96%',
    },
    texto: {
        fontFamily: '"Montserrat", "sans-serif"',
        fontWeight: 900,
        color: 'white',
        fontSize: '3em',
        padding: 0,
        margin: 0,
    },
    paddingDesktop: {
        paddingLeft: '15vw',
        paddingRight: '15vw',
        marginLeft: '0.8vh',
        marginRight: '0.8vh'
    }
});

function CafeteriaPage() {
    const [isMobile, setIsMobile] = useState(false);
    const { width } = useWindowDimensions();
    const classes = useStyles(isMobile);
    const refSwiper = useRef(null);

    useEffect(() => {
        if (width < 960) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    return (
        <>
            <div className={classes.carousel}>
                <div style={{ margin: '3vh' }}>
                    <span className={classes.texto}>CAFE-</span><br />
                    <span className={classes.texto}>TERIA</span>
                </div>
                <div style={!isMobile ? { marginBottom: '7vh' } : { margin: '2%' }}>
                    {width <= 600 ?
                        <SliderMobile />
                        :
                        <>
                            <div style={{ paddingLeft: '12vw', paddingRight: '12vw', display: 'flex' }}>
                                <IconButton onClick={(e) => refSwiper?.current?.swiper?.navigation?.onPrevClick(e)} style={{ padding: 0 }}>
                                    <ArrowBackIos style={{ color: "white", fontSize: '1.6em', width: '3vw' }} />
                                </IconButton>
                                <Swiper loop slidesPerView={3} ref={refSwiper}>
                                    {CafeteriaImages?.map((i) => {
                                        return (
                                            <SwiperSlide key={i.id}>
                                                <img className={classes.imgCarousel} src={i.url} alt={i.altText} style={{borderRadius: '10%'}} />
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                                <IconButton onClick={(e) => refSwiper?.current?.swiper?.navigation?.onNextClick(e)} style={{ padding: 0 }}>
                                    <ArrowForwardIos style={{ color: "white", fontSize: '1.6em', width: '3vw' }} />
                                </IconButton>
                            </div>
                        </>
                    }
                    <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', marginTop: '2%', textAlign: 'center' }} className={!isMobile ? classes.paddingDesktop : ''}>
                        {width <= 600 ?
                            <MenuMobile menu={MenuCafeList} />
                            :
                            <MenuDesktop menu={MenuCafeList} />
                        }
                    </div>
                </div >
            </div>
        </>
    )
}

export default CafeteriaPage
