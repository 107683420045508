import { useEffect, useState } from 'react';
import Fondo from 'imgs/cafeteria-individuales.png'
import useWindowDimensions from 'helpers/getDimensions';

function MenuDesktop({ menu }) {
    const [menuInfo, setMenuInfo] = useState({ firstTable: [], secondTable: [] })
    const { width } = useWindowDimensions();

    useEffect(() => {
        const middleIndex = Math.ceil(menu.length / 2)
        let copyOfMenu = [...menu];
        const firstTable = copyOfMenu.splice(0, middleIndex);
        const secondTable = copyOfMenu.splice(-middleIndex)
        setMenuInfo({ firstTable: firstTable, secondTable: secondTable });
    }, [menu])

    return (
        <div className="cafe-contenedor" >
            <div className="cafe-contenido" style={{ backgroundImage: `url(${Fondo})`, backgroundSize: 'cover', backgroundPosition: '0 center' }}>
                {Object.keys(menuInfo).map((keyName, i) => {
                    return (
                        <ul className="lista-cafe" key={i} style={width > 1500 ? { paddingLeft: '5vw' } : {}}>
                            {
                                menuInfo[keyName].map((x, index) => {
                                    return (
                                        <li style={{ fontWeight: index % 2 === 1 ? 'bold' : 'normal' }} key={i}>{x.title} - {x.price}</li>
                                    )
                                })}
                        </ul>
                    )
                })}
            </div>
            {/* <span className="cafe-icono"> */}
            {/* </span> */}
            {/* <div className="cafe-imagen" style={{ backgroundImage: `url(${Fondo})` }}> */}
            {/* </div> */}
        </div>
    )
}

export default MenuDesktop
