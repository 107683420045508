import { Grid, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/Fondo-LandingCV.png';
import WorkWithUsForm from './components/WorkWithUsForm';
import { LayoutContent } from 'components/Layout/LayoutContent';
import Footer from 'components/Footer/Footer';

function TrabajoComponent() {
    const classes = useStyles();
    const { width } = useWindowDimensions();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        if (width < 960) {
            setIsMobile(false)
        } else {
            setIsMobile(true)
        }
    }, [width])



    return (
        <>
            <LayoutContent>
                <Grid container className={isMobile ? classes.main : classes.mobileMain} id="contacto">
                    <Grid item md={6} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <div style={{ textAlign: isMobile ? 'left' : 'center' }}>
                            <h1 className={classes.title} style={{ color: '#ec3237', fontSize: width >= 960 ? '3em' : '2em' }}>¡ SUMATE !</h1>
                            <h1 className={classes.title} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>A NUESTRO</h1>
                            <h1 className={classes.title} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>EQUIPO</h1>
                            <h1 className={classes.title} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>—</h1>
                        </div>
                        <div style={{ textAlign: 'left' }}>
                            <WorkWithUsForm />
                        </div>
                    </Grid>
                    <Grid item md={1} xs={12} >
                    </Grid>
                </Grid >
                <Footer />
            </LayoutContent>
        </>

    )
}

const useStyles = makeStyles({
    title: {
        fontFamily: '"Montserrat", "sans-serif"',
        fontWeight: 900,
        fontStyle: 'bold',
        color: "#474747",
        margin: 0,
        padding: 0
    },
    main: {
        padding: '12vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed'
    },
    mobileMain: {
        padding: '5vw',
        marginTop: '12vh'
    }
});

export default TrabajoComponent

