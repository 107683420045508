import { Checkbox, Dialog, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup } from '@material-ui/core'
import React from 'react'

function TermsAndConditions({ checked, setChecked }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        color='success'
                    />}
                    label={
                        <div>
                            He leído y acepto los <span style={{ textDecoration: 'underline', textDecorationColor: 'red', color: 'red', cursor: 'pointer' }} onClick={() => { handleClickOpen(); setChecked(!checked) }}>términos y condiciones</span>
                        </div>
                    } />
            </FormGroup>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Aquí están los Términos y Condiciones junto a la Política de Privacidad
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <h1>Términos y Condiciones</h1>

                        Esta página web, en adelante el “Sitio”, bajo el dominio “bigburger.com.ar” como así también cualquiera de sus subdominios es propiedad y es operado por 
                        <b> Alimentaria Central del Norte S.R.L.</b>, en adelante “Big Burger”.

                        <br/><br/>

                        Estos Términos y Condiciones, en adelante “Términos” establecen los términos y condiciones bajo los cuales se puede usar nuestra página web y servicios ofrecidos. 
                        El Sitio ofrece a los visitantes información acerca de los productos y/o servicios ofrecidos por Big Burger. Al acceder o usar el Sitio, apruebas que hayas leído, entendido y 
                        aceptado estar sujeto a estos Términos.

                        <br/><br/>
                        <b><span style={{ color: "red" }}>SI DECIDES NO ACEPTAR LOS TÉRMINOS Y CONDICIONES DE USO DEBERÁS ABSTENERTE DE INGRESAR Y/O UTILIZAR DE CUALQUIER MANERA EL SITIO.</span></b>
                        <br/><br/>
                        Por lo tanto:
                        <ul>
                            <li>
                                Para usar nuestra página web y/o recibir nuestros servicios, debes tener al menos 18 años de edad, 
                                o la mayoría de edad legal en tu jurisdicción, y poseer la autoridad legal, el derecho y la libertad 
                                para participar en estos Términos como un acuerdo vinculante. No tienes permitido utilizar esta página web
                                 y/o recibir servicios si hacerlo está prohibido en tu país o en virtud de cualquier ley o regulación aplicable a tu caso.
                            </li>
                            <li>
                                No podrás vender o modificar el contenido o reproducirlo, exhibirlo, representarlo en público, distribuirlo, 
                                o hacer otro uso de este contenido con fines comerciales o de difusión. Está prohibido el uso del contenido 
                                en cualquier otro sitio web.
                            </li>
                            <li>
                                Los contenidos del Sitio puestos a disposición del Usuario, tales como texto, gráficos, imágenes, logos, isologos y
                                cualquier otro material, están protegidos por la legislación sobre propiedad intelectual y son de propiedad exclusiva de
                                Big Burger o de sus proveedores u otros terceros. Queda prohibida su utilización y/o reproducción sin expresa autorización 
                                de Big Burger y/o sus respectivos propietarios.
                            </li>
                            <li>
                                Los juguetes Big Burger y artículos promocionados sólo están disponibles en los restaurantes participantes hasta agotar stock,
                                pero no están disponibles en la página web de la empresa.
                            </li>
                            <li>
                                Debes tener en cuenta que las imágenes incluidas en el Sitio son a modo ilustrativo y que las especificaciones y/o descripciones
                                son lo más aproximadas posibles y están sujetas a cambios por errores involuntarios o imprecisiones, sin previo aviso, pudiendo a
                                su vez variar las características de los productos adquiridos. Los precios de los productos publicados en el Sitio podrán no coincidir
                                con los ofrecidos en los locales Big Burger.
                            </li>
                            <li>
                                Big Burger mantiene como política no revisar las ideas de marketing o envíos no solicitados con el fin de evitar malentendidos
                                con respecto a la propiedad de las ideas o sugerencias de terceros ajenos a la empresa.
                            </li>
                            <li>
                                Big Burger se reserva el derecho, a su exclusiva discreción, de interrumpir, modificar o alterar el contenido del sitio y/o acceso al mismo,
                                así como también de excluir al Usuario y/o a sus datos ingresados, en cualquier momento y sin previo aviso.
                            </li>
                            <li>
                                Big Burger no garantiza ni asume ningún tipo de responsabilidad por los daños y perjuicios sufridos como consecuencia del acceso al Sitio
                                por parte de terceros a través de conexiones, vínculos o links de los sitios enlazados, ni tampoco por enlaces a sitios de terceros que tú puedas acceder desde el Sitio.
                            </li>
                        </ul>

                        Además no podrás:
                        <ul>
                            <li>
                                Utilizar el Sitio para la realización de actos contrarios a la ley, la moral, el orden público, los presentes Términos y Condiciones de Uso.
                            </li>
                            <li>
                                Introducir o difundir en el Sitio o en la red, programas de datos (virus y software nocivo) susceptibles de provocar daños en los sistemas
                                informáticos del Sitio o el proveedor de acceso, sus proveedores o terceros usuarios de la red Internet.	
                            </li>
                            <li>
                                Emplear el Sitio para realizar actividades ilícitas o constitutivas de delito, que atenten contra los derechos de terceros y/o que infrinjan
                                la regulación sobre propiedad intelectual, o cualquier otra norma del ordenamiento jurídico aplicable.	
                            </li>
                            <li>
                                Transmitir, introducir, difundir y/o poner a disposición de terceros, cualquier tipo de material e información (datos contenidos, mensajes, dibujos,
                                archivos de sonido e imagen, fotografías, software, etc.) que sean contrarios a la ley, la moral, el orden público y a estos Términos y Condiciones de Uso.
                            </li>
                        </ul>

                        <b>
                            <span style={{ color: "black" }}>
                                En caso de no cumplir el Usuario con lo previsto en esta cláusula, Big Burger se reserva el
                                derecho de iniciar las acciones legales que por derecho le correspondan.
                            </span>
                        </b>
                        <h1>Política de Privacidad</h1>
                        
                            El objeto de esta Política de Privacidad es informar a los Usuarios la forma en que el sitio web, en adelante el “Sitio”,
                            de titularidad exclusiva de <b>Alimentaria Central del Norte S.R.L.</b> (en adelante “Big Burger”), lleva a cabo el tratamiento
                            de los datos personales de los Usuarios (en adelante, el “Usuario”) que se registran en el Sitio o bien que navegan por el mismo.
                            Por favor, lea esta Política de Privacidad cuidadosamente a fin de entender exactamente las prácticas en relación con los datos personales
                            de los Usuarios. Si tiene dudas sobre cualquier aspecto de esta Política de Privacidad, por favor envíe un correo electrónico
                            a <a href="mailto:info@bigburger.com.ar?subject=Ley 25326">info@bigburger.com.ar</a> con asunto “Ley 25326”.
                        <br/><br/>
                        Asimismo, recuerde que la aceptación de esta Política de Privacidad implica la aceptación de los Términos y Condiciones de Uso del Sitio
                        Web bigburger.com.ar y/o cualquiera de sus subdominios.
                        <br/>

                        <h3>Consentimiento para el uso de los datos</h3>
                        Al aceptar esta Política de Privacidad el Usuario presta su consentimiento expreso, libre, informado y sin limitaciones para
                        la recolección, tratamiento, uso, almacenamiento, divulgación, cesión o transferencia de sus datos personales de acuerdo con
                        esta Política de Privacidad y con lo establecido en la ley 25.326.
                        <br/><br/>
                        <b><span style={{ color: "red" }}>SI EL USUARIO NO ESTÁ DE ACUERDO CON ESTA POLÍTICA DE PRIVACIDAD, ENTONCES POR FAVOR NO PROVEA NINGÚN DATO PERSONAL A TRAVÉS DEL SITIO.</span></b>
                        <br/><br/>
                        Por favor advierta que el Sitio requiere de ciertos datos personales para poder operar y prestar algunos de los servicios
                        ofrecidos a los Usuarios, por lo cual si el Usuario no provee esos datos, Big Burger podría no estar en condiciones
                        de proveer algunos de los servicios ofrecidos en el Sitio.

                        <h3>Como Big Burger utiliza los datos personales que recolecta</h3>
                        La información del Usuario que pueda contener datos personales, obtenida durante el
                        uso del Sitio es utilizada por el Titular de la Base de Datos para:
                        <ul>
                            <li>
                                Prestar los servicios accesibles a través del Sitio.
                            </li>
                            <li>
                                Utilizarlos en futuras comunicaciones con el Usuario.
                            </li>
                            <li>
                                Fines estadísticos, de investigación y de creación y mejora de procesos y servicios comerciales,
                                incluyendo la mejora y personalización del Sitio.
                            </li>
                            <li>
                                Fines de selección de personal cuando el usuario haya proporcionado los datos a través de la sección “Sumate” del Sitio.
                            </li>
                        </ul>

                        El Sitio almacena los datos personales y no personales que recolecta en un servidor de almacenamiento brindado por
                        Amazon Web Services, cumpliendo con las normas de seguridad previstas en la normativa vigente.
                        <br></br>
                        Si la finalidad de la recolección de los datos fuera otra distinta a las aquí enunciadas,
                        la misma se notificará en forma expresa al momento de requerirse el ingreso de sus datos.

                        <br/><br/>
                        En el Sitio pueden aparecer vínculos a sitios operados por terceros.
                        Big Burger desconoce y no se responsabiliza por las prácticas de privacidad de estos terceros.
                        Si el Usuario elige ingresar a dichos vínculos y por ende, a páginas de terceros, debe leer y aceptar
                        las Políticas de Privacidad que indican cómo estos terceros recolectan, usan, divulgan, transfieren y eliminan
                        los datos personales de sus Usuarios y visitantes. El Titular de la Base de Datos no divulga datos personales de los Usuarios
                        a otros Usuarios. Es una decisión del Usuario divulgar datos personales directamente a otros Usuarios.
                        <br/><br/>

                        Big Burger garantiza a sus Usuarios el acceso a sus datos personales almacenados por el Sitio, así como el derecho a solicitar su actualización,
                        corrección o eliminación, todo ello en virtud de lo dispuesto en los arts. 14 y 16 de la Ley 25.326 de Protección de los Datos Personales.
                        Para acceder a sus datos personales o solicitar la actualización, corrección o eliminación el Usuario deberá comunicarse a info@bigburger.com.ar
                        con asunto “Ley 25326”, acreditar personería de manera fehaciente y completar formulario pre establecido al efecto.
                        Tenga en cuenta que alguna información residual, que puede o no contener datos personales, puede mantenerse en la base de datos u otros
                        registros que Big Burger no puede eliminar por motivos legales o porque son necesarios para seguir prestando los servicios al Usuario.
                        Será decisión del Usuario determinar si procede a eliminar sus datos personales en caso que ello imposibilite seguir prestando servicios al
                        Usuario. La información se actualizará o eliminará de forma inmediata una vez que el usuario confirme la decisión de la actualización o
                        eliminación de los datos personales.
                        <br/><br/>
                        De manera adicional y sin su oposición, la empresa utilizará sus datos personales para finalidades adicionales que no son necesarias para
                        proporcionar nuestros productos pero que creemos que nos permiten acercarnos más a los clientes y usuarios de nuestros servicios u operar
                        nuestras plataformas virtuales, siempre con el fin de brindar una mejor atención. Para ello la empresa utilizará dichos datos para enviarle
                        publicidad dirigida, promociones y ofertas, encuestas para lograr desarrollar y mejorar el servicio al cliente, promociones, productos y servicios.
                        <br/><br/>

                        Para el caso en que usted no desee que sus datos personales sean utilizados por la empresa para fines adicionales, le pedimos que nos lo haga saber enviándonos
                        un correo electrónico de manera informal a <a href="mailto:info@bigburger.com.ar">info@bigburger.com.ar</a>.
                        <br/><br/>

                        Si bien nos comprometemos a tener visible, disponible y actualizadas nuestras políticas de privacidad en nuestro sitio web oficial, el presente aviso
                        de publicidad podrá sufrir modificaciones para cumplir con requerimientos legales o por cualquier otra razón que Big Burger considere conveniente.
                        <br/><br/>

                        Por favor tome en consideración que Big Burger revisa sus prácticas de privacidad y modifica su política de privacidad periódicamente, publicando
                        los cambios por este mismo medio. Cualquier cambio o modificación será efectiva inmediatamente desde la publicación en el Sitio. La continuidad de uso del
                        Sitio por parte del Usuario implica el consentimiento expreso con las modificaciones efectuadas.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default TermsAndConditions
