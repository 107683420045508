import { AppBar, IconButton, makeStyles } from '@material-ui/core';
import configurableVariables from 'configuration';
import useWindowDimensions from 'helpers/getDimensions';
import facebook from 'imgs/icons/Facebook-Logo.png';
import instagram from 'imgs/icons/Instagram-Logo.png';
import React, { useEffect, useState } from 'react';
import DisplayDesktop from './DesktopMenu';
import DisplayMobile from './MobileMenu';

export const LayoutContent = ({ children, ...props }) => {
    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(false);
    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width < 960) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    return (
        <div>
            <AppBar position="static" className={classes.menu}>
                {!isMobile ? <DisplayDesktop menu={props.menu} logoClass={classes.logo} listClass={classes.list} /> : <DisplayMobile menu={props.menu} logo={classes.logo} />}
            </AppBar>
            <div style={{ position: 'fixed', bottom: '0%', top: '0%', right: 0, left: 0, zIndex: 1, width: '100vw', overflowY: 'scroll' }}>
                <div style={{ height: '100%' }}>
                    {children}
                </div>
            </div>
            {!isMobile &&
                <div style={{ position: 'fixed', top: '70vh', right: '5vh', zIndex: 2 }}>
                    <IconButton className={classes.icon} color="secondary" target="_blank" rel="noreferrer" href={configurableVariables.facebook}>
                        <img className={classes.imageIcon} src={facebook} alt='logo facebook'/>
                    </IconButton>
                    <br />
                    <IconButton className={classes.icon} color="secondary" target="_blank" rel="noreferrer" href={configurableVariables.instagram}>
                        <img className={classes.imageIcon} src={instagram} alt='logo facebook'/>
                    </IconButton>
                </div>
            }
        </div>
    )
}

const useStyles = makeStyles({
    menu: {
        zIndex: 999,
        position: 'relative',
        boxShadow: 'none',
        background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);',
        paddingTop: '3vh',
        paddingLeft: '10vw',
        paddingRight: '10vw',
    },
    logo: {
        width: 'min(50vw, 150px)'
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        '& span, & svg': {
            fontSize: '.8rem'
        }
    },
    icon: {
        paddingRight: 15,
        padding: 5,
    },
    imageIcon: {
        width: '2.2em'
    }
});
