import { Box, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

function ListItemLink({ border, ...props }) {
    return (
        <Box borderRight={border ? 1 : 0}>
            <ListItem button component="a" {...props} style={{ fontSize: '0.4em' }} />
        </Box>
    )
}

function ListItemRouter({ border, ...props }) {
    return (
        <Box borderRight={border ? 1 : 0} >
            <ListItem button component={Link} to={props.href} {...props} style={{ fontSize: '0.4em' }} />
        </Box>
    )
}

const DisplayDesktop = ({ menu, logoClass, listClass }) => {
    const path = window.location.pathname;
    const hash = window.location.hash;

    const style = (itemPath) => {
        let styleToReturn = {};
        if (path === "/" && hash !== "#home") {
            if (hash) {
                styleToReturn = {
                    fontSize: '.8rem',
                    fontWeight: hash === itemPath ? 600 : 300
                }
                return styleToReturn;
            }
        } else if (hash === "#comoPedir") {
            styleToReturn = {
                fontSize: '.8rem',
                fontWeight: hash === itemPath ? 600 : 300
            }
            return styleToReturn;
        }

        styleToReturn = {
            fontSize: '.8rem',
            fontWeight: path === itemPath ? 600 : 300
        }
        return styleToReturn;
    }

    return (
        <Toolbar>
            <Box display='flex' flexGrow={1}>
                <img src='/logo.png' className={logoClass} alt='logo'/>
            </Box>
            <List className={listClass} disablePadding>
                {
                    !menu ?
                        <>
                            {
                                window.location.pathname !== '/Sumate' ?
                                    <>
                                        <ListItemLink href='#home' border={true}>
                                            <Typography type="span" style={style('/')}>INICIO</Typography>
                                        </ListItemLink>
                                        <ListItemRouter href="/Combos" border={true} >
                                            <Typography type="span" style={style('/Combos')}>MENÚ</Typography>
                                        </ListItemRouter>
                                        <ListItemLink href="#locales" border={true} >
                                            <Typography type="span" style={style('#locales')}>LOCALES</Typography>
                                        </ListItemLink>
                                        <ListItemLink href="#contacto" border={true}>
                                            <Typography type="span" style={style('#contacto')}>CONTACTO</Typography>
                                        </ListItemLink>
                                    </>
                                    :
                                    <>
                                        <ListItemRouter href={'/'} border={true}>
                                            <Typography type="span" style={style('/')}>INICIO</Typography>
                                        </ListItemRouter>
                                        <ListItemRouter href="/Combos" border={true} >
                                            <Typography type="span" style={style('/Combos')}>MENÚ</Typography>
                                        </ListItemRouter>
                                        <ListItemRouter href="/#locales" border={true} >
                                            <Typography type="span" style={style('#locales')}>LOCALES</Typography>
                                        </ListItemRouter>
                                        <ListItemRouter href="/#contacto" border={true}>
                                            <Typography type="span" style={style('#contacto')}>CONTACTO</Typography>
                                        </ListItemRouter>
                                    </>
                            }

                            <ListItemRouter href="/Sumate" border={false}>
                                <Typography type="span" style={style('/Sumate')}>SUMATE</Typography>
                            </ListItemRouter>
                        </>
                        :
                        <>
                            <ListItemRouter href="/" border={true} >
                                <Typography type="span" style={style('/')}>INICIO</Typography>
                            </ListItemRouter>
                            <ListItemRouter href="/Combos" border={true} >
                                <Typography type="span" style={style('/Combos')}>COMBOS</Typography>
                            </ListItemRouter>
                            <ListItemRouter href="/Individuales" border={true} >
                                <Typography type="span" style={style('/Individuales')}>INDIVIDUALES</Typography>
                            </ListItemRouter>
                            <ListItemRouter href="/Cafeteria" border={true}>
                                <Typography type="span" style={style('/Cafeteria')}>CAFETERIA & POSTRES</Typography>
                            </ListItemRouter>
                            <ListItemLink href="#comoPedir" border={false}>
                                <Typography type="span" style={style('#comoPedir')}>¿ CÓMO PEDIR ?</Typography>
                            </ListItemLink>
                        </>
                }

            </List>
        </Toolbar>
    )
};

export default DisplayDesktop;
