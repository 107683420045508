import Pontevedra from 'imgs/locales-thumbnail/pontevedra.jpg';
import Libertad from 'imgs/locales-thumbnail/libertad.png';
import MarcosPaz from 'imgs/locales-thumbnail/marcospaz.jpg';
import RafaelCastillo from 'imgs/locales-thumbnail/rafael_castillo.png';

export const LocalData = [
    {
        "image": Pontevedra,
        "location": "PONTEVEDRA",
        "address": "Av. La Union 1844",
        "phone": "11 3877 9354",
        "link": "https://wa.me/5491138779354",
        "coverageLink": "https://www.google.com/maps/d/edit?mid=1FdBiu3gzC82oQlJdQck1QpYkN9pSH6mI&usp=sharing"
    },
    {
        "image": Libertad,
        "location": "LIBERTAD",
        "address": "Av. Eva Perón 4475",
        "phone": "11 4028 3020",
        "link": "https://wa.me/5491140283020",
        "coverageLink": "https://www.google.com/maps/d/edit?mid=1RgNba-dUDtrBtvdwA2hdZn2eBSnYFGdb&usp=sharing"
    },
    {
        "image": MarcosPaz,
        "location": "MARCOS PAZ",
        "address": "Av. Independencia 150",
        "phone": "11 4185 0017",
        "link": "https://wa.me/5491141850017",
        "coverageLink": "https://www.google.com/maps/d/edit?mid=1Ocye8lo0vFT-Z6Jk3v2qP1TpBX0m2Pyl&usp=sharing"
    },
    {
        "image": RafaelCastillo,
        "location": "RAFAEL CASTILLO",
        "address": "Chavarría 1665",
        "phone": "11 35564556",
        "link": "https://wa.me/5491135564556",
        "coverageLink": "#"
    }
]