import Slide1 from 'imgs/slide-mobile/Combo06.png';
import Slide2 from 'imgs/slide-mobile/Combo10.png';
import Slide3 from 'imgs/slide-mobile/Combo14.png';
import Slide4 from 'imgs/slide-mobile/Combo16.png';

const CarouselImagesMobile = [
    {
        id: 1,
        url: Slide1,
    },
    {
        id: 2,
        url: Slide2,
    }, {
        id: 3,
        url: Slide3,
    }, {
        id: 4,
        url: Slide4,
    }
];

export default CarouselImagesMobile;