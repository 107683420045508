import { makeStyles, TextField, withStyles } from '@material-ui/core';
import React from 'react';

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ec3237',
                borderWidth: '0.2vw',
                borderRadius: '2em'
            },
        },
    },
})(TextField);

function InputForm(props) {
    const classes = useStyles();

    return (
        <CssTextField className={classes.input} variant="outlined" size="small" fullWidth placeholder={props.placeholder} {...props} />
    )
}

const useStyles = makeStyles({
    input: {
        marginBottom: '2%',
    }
});

export default InputForm

