import Slide6 from 'imgs/Slide-Home-Combo6.png';
import Slide10 from 'imgs/Slide-Home-Combo10.png';
import Slide14 from 'imgs/Slide-Home-Combo14.png';
import Slide16 from 'imgs/Slide-Home-Combo16.png';

const CarouselImages = [
    {
        id: 1,
        url: Slide6,
    },
    {
        id: 2,
        url: Slide10,
    }, {
        id: 3,
        url: Slide14,
    }, {
        id: 4,
        url: Slide16,
    }
];

export default CarouselImages;