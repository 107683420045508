import { Button, CircularProgress, MenuItem, withStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import InputForm from './inputForm';
import SelectForm from './selectForm';
import TermsAndConditions from './TermsAndConditions';

const CustomButton = withStyles({
    root: {
        borderColor: '#ec3237',
        borderWidth: '0.2vw',
        fontWeight: '600',
        borderRadius: '4em',
        marginTop: '2%',
        '&:hover': {
            borderColor: '#ec3237',
            borderWidth: '0.2vw',
            fontWeight: 800,
        },
    },
})(Button);

function ContactForm() {
    const [user, setUser] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [sucursalField, setSucursal] = useState('pontevedra')
    const [message, setMessage] = useState('')
    const [captcha, setCaptcha] = useState(false);
    const [errors, setErrors] = useState({});
    const recaptchaRef = React.createRef();
    const [statusForm, setStatusForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);

    const checkIfErrorIsEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object
    }

    useEffect(() => {
        if (statusForm !== null) {
            setTimeout(() => {
                setStatusForm(null)
            }, 5000)
        }
    }, [statusForm])

    const checkIfFormIsEmpty = () => {
        if (!user || !phone || !email || !message)
            return true
        return false
    }

    const validate = ((regex, name, message) => {
        if (!regex) {
            setErrors({ ...errors, [name]: message })
        }
        else {
            let helper = errors
            delete helper[name]
            setErrors(helper)
        }
    })

    const handleSubmit = () => {
        axios.post(process.env.REACT_APP_CONTACT_FORM, {
            user: user,
            phone: phone,
            email: email,
            sucursal: sucursalField,
            message: message,
            "g-recaptcha-response": recaptchaRef.current.getValue(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    setStatusForm(true);
                    setUser('')
                    setPhone('')
                    setEmail('')
                    setSucursal('pontevedra')
                    setMessage('')
                } else {
                    setStatusForm(false)
                }
            })
            .catch(function (response) {
                setStatusForm(false)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <form onSubmit={(e) => { e.preventDefault(); setLoading(true); handleSubmit(); }}>
            <InputForm name="user" type='text' value={user} placeholder="Nombre y Apellido*" onChange={(e) => { validate(/^([^0-9]*)$/.test(e.target.value), 'user', 'El nombre no es válido', e.target.value); setUser(e.target.value) }} />
            <p style={{ margin: 0, color: '#ec3237' }}>{errors?.user}</p>
            <InputForm name="phone" type='number' value={phone} placeholder="Teléfono*" onChange={(e) => { validate(/^\d+$/.test(e.target.value), 'phone', 'El teléfono no es válido', e.target.value); setPhone(e.target.value) }} />
            <p style={{ margin: 0, color: '#ec3237' }}>{errors?.phone}</p>
            <InputForm name="email" type='email' value={email} placeholder="Email*" onChange={(e) => { validate(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(e.target.value), 'email', 'El email no es válido', e.target.value); setEmail(e.target.value) }} />
            <p style={{ margin: 0, color: '#ec3237' }}>{errors?.email}</p>
            <SelectForm name="sucursal" placeholder="Sucursal" value={sucursalField} onChange={(e) => setSucursal(e.target.value)}>
                <MenuItem key='pontevedra' value='pontevedra'>Pontevedra</MenuItem>
                <MenuItem key='libertad' value='libertad'>Libertad</MenuItem>
                <MenuItem key='marcosPaz' value='marcosPaz'>Marcos Paz</MenuItem>
            </SelectForm>
            <InputForm name="message" type='text' value={message} placeholder="Mensaje*" multiline rows={2} onChange={(e) => setMessage(e.target.value)} />
            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_AUTH} onChange={() => { setCaptcha(true) }} style={{ marginTop: '2%' }} />
            <TermsAndConditions checked={checked} setChecked={setChecked} />
            <CustomButton variant="outlined" color="primary" type="submit" disabled={!captcha || !checkIfErrorIsEmpty(errors) || checkIfFormIsEmpty() || loading}>
                ENVIAR
            </CustomButton>
            {loading && <> <br /> <CircularProgress /></>}
            {statusForm === true ? <p style={{ margin: 0, color: 'green' }}>✔️ Enviado exitosamente</p> : statusForm === false && <p style={{ margin: 0, color: '#ec3237' }}>❌ Ocurrió un error</p>}
        </form>
    )
}

export default ContactForm

