import { ThemeProvider } from '@material-ui/core';
import 'App.css';
import TrabajoComponent from 'pages/Home/TrabajaConNosotros';
import Analytics from 'react-router-ga';
import MenuPage from 'pages/Menu';
import CafeteriaYPostresPage from 'pages/Menu/Cafeteria';
import IndividualesPage from 'pages/Menu/Individuales/Individuales';
import {
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom';

import HomePage from './pages/Home';
import theme from './theme';

// import Popup from 'reactjs-popup';
// import 'reactjs-popup/dist/index.css';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Analytics id={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}>
            <Switch>

              <Route exact path='/'>
                <HomePage />
              </Route>

              <Route path='/Combos'>
                <MenuPage />
              </Route>

              <Route path='/Individuales'>
                <IndividualesPage />
              </Route>

              <Route path='/Cafeteria'>
                <CafeteriaYPostresPage />
              </Route>

              <Route path='/Sumate'>
                <TrabajoComponent />
              </Route>

            </Switch>
            {/* <PopupExample /> */}
          </Analytics>
        </Router>
      </ThemeProvider>
    </div>
  );
}

// const PopupExample = () => (
//   <Popup trigger={<button className="button"> Open Modal </button>} modal>
//     {(close: any) => (
//       <div>
//         Content here
//         <a className="close" onClick={close}>
//           &times;
//         </a>
//       </div>
//     )}
//   </Popup>
// );

export default App;
