import { Grid, makeStyles } from '@material-ui/core';
import { LocalData } from 'constants/local';
import LocalCard from './components/LocalCard';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/fondo-encontranos-home.png';

function LocalesComponent() {
    const classes = useStyles();
    const { width } = useWindowDimensions();

    const styleMobile = {
        backgroundColor: '#EBDB34'
    }

    const styleDesktop = {
        minHeight: '100%',
        backgroundImage: `url(${Background})`
    }

    return (
        <div className={classes.principalDiv} style={width >= 960 ? styleDesktop : styleMobile} id="locales">
            <div style={{ marginTop: '10vh', marginBottom: '5vh' }}>
                <h1 className={classes.text} style={{ color: '#ec3237', fontSize: width >= 960 ? '3em' : '2em' }}>¡ ENCONTRANOS !</h1>
                <h1 className={classes.text} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>ELEGÍ TU LOCAL</h1>
                <h1 className={classes.text} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>MAS CERCANO</h1>
                <h1 className={classes.text} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>——</h1>
            </div>
            <Grid container>
                <Grid item md={2} xs={12} />
                {LocalData.map((i, x) =>
                    <LocalCard 
                        image={ i.image } 
                        location={ i.location } 
                        address={ i.address } 
                        phone={ i.phone } 
                        link={ i.link } 
                        key={ x } 
                        coverageLink={ i.coverageLink } />
                )}

            </Grid>
        </div>
    )
}

const useStyles = makeStyles({
    principalDiv: {
        backgroundSize: 'cover',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    text: {
        fontSize: '3em',
        fontFamily: '"Montserrat", "sans-serif"',
        fontWeight: 900,
        margin: 0,
        padding: 0,
        fontStyle: 'bold',
        color: "#474747"
    }
});

export default LocalesComponent

