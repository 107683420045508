import Imagen1 from 'imgs/Carrusel-Combos-Cafeteria/Combo1.png';
import Imagen2 from 'imgs/Carrusel-Combos-Cafeteria/Combo2.png';
import Imagen3 from 'imgs/Carrusel-Combos-Cafeteria/Combo3.png';
import Imagen4 from 'imgs/Carrusel-Combos-Cafeteria/Combo4.png';
import Imagen5 from 'imgs/Carrusel-Combos-Cafeteria/Combo5.png';

const CafeteriaImages = [
    {
        id: 1,
        url: Imagen1,
        altText: 'Combo Cafetería 1'
    },
    {
        id: 2,
        url: Imagen2,
        altText: 'Combo Cafetería 2'
    }, {
        id: 3,
        url: Imagen3,
        altText: 'Combo Cafetería 3'
    }, {
        id: 4,
        url: Imagen4,
        altText: 'Combo Cafetería 4'
    }, {
        id: 5,
        url: Imagen5,
        altText: 'Combo Cafetería 5'
    }
];

export default CafeteriaImages;