import { Grid, IconButton, makeStyles } from '@material-ui/core'
import { RoomOutlined, WhatsApp } from '@material-ui/icons'

interface Local {
    image: string;
    location: string;
    address: string;
    phone: string;
    link: string;
    coverageLink: string;
}


function LocalCard(data: Local) {
    const classes = useStyles();

    return (
        <Grid item md={ 2 } xs={ 12 }>
            <a href= { data.coverageLink } className={ classes['a:link'] } target="_blank" rel="noreferrer">
                <img src={ data.image } className={ classes.image } alt="" />
                <p className={ classes.title }>BIG BURGER</p>
                <p className={ classes.title }>{ data.location }</p>
                <RoomOutlined color="primary" style={{ fontSize: '3em' }}/>
            </a>
            <p className={ classes.text } style={ { fontWeight: 600 } }>{ data.address }</p>
            <p className={ classes.text } style={{ fontWeight: 700 }} >{ data.phone }</p>
            <IconButton target="_blank" href={ data.link } rel="noreferrer">
                <WhatsApp style={{ color: "#474747", marginTop: '1vh', marginBottom: '1vh', fontSize: '1.6em' }} />
            </IconButton>
        </Grid>
    )
}

const useStyles = makeStyles({
    image: {
        width: 'min(30vw, 120px)',
        borderStyle: 'solid',
        borderColor: '#9CD2F5',
        borderRadius: '50%',
        borderWidth: '.6em',
    },
    title: {
        color: '#ec3237',
        fontWeight: 800,
        fontSize: '1.2em',
        padding: 0,
        margin: 0,
    },
    text: {
        padding: 0,
        margin: 0,
        fontSize: "1em",
        color: "#474747"
    },
    "a:link": {
        "text-decoration": "none"
    }
});

export default LocalCard
