import { makeStyles, TextField, withStyles } from '@material-ui/core';
import React from 'react';

const CssTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#ec3237',
                borderWidth: '0.2vw',
                borderRadius: '2em'
            },
        },
    },
})(TextField);

function SelectForm({ children, value, onChange, placeholder, name }) {
    const classes = useStyles();
    return (
        <CssTextField className={classes.input} variant="outlined" size="small" fullWidth placeholder={placeholder} select
            value={value} onChange={onChange} name={name}>
            {children}
        </CssTextField>
    )
}

const useStyles = makeStyles({
    input: {
        marginBottom: '5%'
    }
});

export default SelectForm

