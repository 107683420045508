import CafeteriaImages from 'mock/CafeteriaImages';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
// Import Swiper styles
import 'swiper/components/navigation/navigation.scss';
import 'swiper/swiper.scss';

SwiperCore.use([Autoplay, Navigation, Pagination]);

function SliderMobile() {

    return (
        <>
            <Swiper loop navigation>
                {CafeteriaImages?.map((i) => {
                    return (
                        <SwiperSlide key={i.id}>
                            <img src={i.url} alt={i.altText} style={{ borderRadius: '10%', width: '95%' }} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </>
    )
}

export default SliderMobile
