const MenuCafeList = [
    {
        id: 1,
        title: "CAFÉ CORTO",
        price: "$2100",
    },
    {
        id: 2,
        title: "CAFÉ DOBLE",
        price: "$2500",
    },
    {
        id: 3,
        title: "LÁGRIMA",
        price: "$2500",
    },
    {
        id: 4,
        title: "CAFÉ CON LECHE",
        price: "$2900",
    },
    {
        id: 5,
        title: "CAPUCHINO",
        price: "$3200",
    },
    {
        id: 6,
        title: "SUBMARINO",
        price: "$3200",
    },
    {
        id: 7,
        title: "LECHE",
        price: "$2500",
    },
    {
        id: 8,
        title: "TÉ",
        price: "$2000",
    },
    {
        id: 9,
        title: "TÉ CON LECHE",
        price: "$2500",
    },
    {
        id: 10,
        title: "MATECOCIDO",
        price: "$2000",
    },
    {
        id: 11,
        title: "MEDIALUNAS",
        price: "$500",
    },
    {
        id: 12,
        title: "TOSTADO JyQ",
        price: "$3800",
    },
    {
        id: 13,
        title: "DONAS",
        price: "$950",
    },
    {
        id: 14,
        title: "MUFFIN",
        price: "$1400",
    },
];

export default MenuCafeList;