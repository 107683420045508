import { Button, Grid, makeStyles } from '@material-ui/core';
import UbicationIcon from 'imgs/icons/Ubicacion-Logo-Combos.png';

interface Local {
    title: string;
    link: string;
    coverageLink: string;
}

function CardMenu(data: Local) {
    const classes = useStyles();

    return (
        <Grid item md={2} xs={12}>
            <a href={ data.coverageLink } className={ classes['a:link'] } target="_blank" rel="noreferrer">
                <img src={ UbicationIcon } className={ classes.image } alt='Ícono de ubicación'/>
                <p className={ classes.title }>BIG BURGER</p>
                <p className={ classes.title }>{ data.title }</p>
            </a>
            <Button variant="contained" target="_blank" href={data.link} rel="noreferrer"
                style={{ paddingTop: 0, paddingBottom: 0, fontSize: '1.2em', fontWeight: 900, color: 'white', backgroundColor: '#00D954', borderRadius: 50, marginTop: '1em', marginBottom: '1em', width: '8em' }}>
                PEDIR
            </Button>
        </Grid>
    )
}

const useStyles = makeStyles({
    image: {
        width: 'min(100vw, 70px)',
        marginBottom: '1em'
    },
    title: {
        color: '#ec3237',
        fontSize: '1.2em',
        fontWeight: 700,
        padding: 0,
        margin: 0,
        fontFamily: '"Montserrat", "sans-serif"',
    },
    text: {
        padding: 0,
        margin: 0,
        fontSize: "0.7em",
        color: "#474747"
    },
    "a:link": {
        "text-decoration": "none"
    }
});

export default CardMenu
