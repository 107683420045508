import { Button, CircularProgress, MenuItem, withStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import InputForm from './inputForm';
import SelectForm from './selectForm';
import TermsAndConditions from './TermsAndConditions';

const CustomButton = withStyles({
    root: {
        borderColor: '#ec3237',
        borderWidth: '0.2vw',
        fontWeight: '600',
        borderRadius: '4em',
        marginTop: '2%',
        '&:hover': {
            borderColor: '#ec3237',
            borderWidth: '0.2vw',
            fontWeight: 800,
        },
    },
})(Button);

const defaultStatus = {
    user: '',
    phone: '',
    motivoTrabajo: '',
    composicionFamilia: '',
    conyuge: 'default',
    cuidadoHijo: 'default',
    accionJudicial: 'default',
    tiempoMotivo: '',
    comoSeConsidera: '',
    trabajaOEstudia: '',
    RazonNoTrabajo: '',
    EnfermedadOTratamiento: '',
    Medicamento: '',
    ExperienciaGastronomia: '',
    QueEsperaDelTrabajo: '',
    renumeracionPretendida: '',
    sucursal: 'default',
    message: ''
}

function WorkWithUsForm() {
    const [captcha, setCaptcha] = useState(false);
    const [errors, setErrors] = useState({});
    const recaptchaRef = React.createRef();
    const [statusForm, setStatusForm] = useState(null);
    const cvRef = useRef(null)
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(defaultStatus);
    const [checked, setChecked] = useState(false);

    const setMessage = (e) => {
        setDataForm({ ...dataForm, [e.name]: e.value })
    }

    const checkIfErrorIsEmpty = (obj) => {
        return obj && Object.keys(obj).length === 0 && obj.constructor === Object
    }

    useEffect(() => {
        if (statusForm !== null) {
            setTimeout(() => {
                setStatusForm(null)
            }, 5000)
        }
    }, [statusForm])

    const validate = ((regex, name, message) => {
        if (!regex) {
            setErrors({ ...errors, [name]: message })
        }
        else {
            let helper = errors
            delete helper[name]
            setErrors(helper)
        }
    })

    const checkIfEmpty = () => {
        if (!dataForm.user || !dataForm.phone || !dataForm.motivoTrabajo || !dataForm.composicionFamilia || dataForm.conyuge === 'default' || dataForm.cuidadoHijo === 'default' || dataForm.accionJudicial === 'default' || !dataForm.tiempoMotivo || !dataForm.comoSeConsidera || !dataForm.trabajaOEstudia || !dataForm.RazonNoTrabajo || !dataForm.renumeracionPretendida || dataForm.sucursal === 'default')
            return true
        return false
    }


    const handleSubmit = () => {
        const data = {
            ...dataForm,
            cv: cvRef.current.files[0],
            "g-recaptcha-response": recaptchaRef.current.getValue(),
        }

        let formData = new FormData()
        Object.keys(data).forEach((key) => {
            formData.append(key, data[key])
        })

        axios.post(process.env.REACT_APP_CONTACT_FORM, formData)
            .then(function (response) {
                if (response.status === 200) {
                    setStatusForm(true);
                    setDataForm(defaultStatus)
                } else {
                    setStatusForm(false)
                }
            })
            .catch(function (response) {
                setStatusForm(false)
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return (
        <form onSubmit={(e) => { e.preventDefault(); setLoading(true); handleSubmit(); }}>
            <InputForm name="user" type='text' value={dataForm?.user} placeholder="Nombre y Apellido*"
                onChange={(e) => { validate(/^([^0-9]*)$/.test(e.target.value), e.target.name, 'El nombre no es válido', e.target.value); setMessage(e.target) }} />
            <p style={{ margin: 0, color: '#ec3237' }}>{errors?.user}</p>

            <InputForm name="phone" type='number' value={dataForm?.phone} placeholder="Teléfono*"
                onChange={(e) => { validate(/^\d+$/.test(e.target.value), e.target.name, 'El teléfono no es válido', e.target.value); setMessage(e.target) }} />
            <p style={{ margin: 0, color: '#ec3237' }}>{errors?.phone}</p>

            {/** Formulario pre laboral */}

            <InputForm name="motivoTrabajo" type='text' value={dataForm?.motivoTrabajo} multiline rows={2}
                placeholder="Indique el motivo por el cuál quiere trabajar*"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="composicionFamilia" type='text' value={dataForm?.composicionFamilia} multiline rows={2}
                placeholder="Composición de la familia donde vive*"
                onChange={(e) => { setMessage(e.target) }} />

            <SelectForm name="conyuge" placeholder="¿Su cónyuge se encuentra trabajando actualmente?" value={dataForm?.conyuge} onChange={(e) => setMessage(e.target)}>
                <MenuItem key='default' value='default' disabled>¿Su cónyuge se encuentra trabajando actualmente?*</MenuItem>
                <MenuItem key='Sí' value='Sí'>Sí</MenuItem>
                <MenuItem key='No' value='No'>No</MenuItem>
                <MenuItem key='No tengo' value='noTengo'>No tengo</MenuItem>
            </SelectForm>

            <SelectForm name="cuidadoHijo" placeholder="¿Tiene quién cuide a su hijo?" value={dataForm?.cuidadoHijo} onChange={(e) => setMessage(e.target)}>
                <MenuItem key='default' value='default' disabled>¿Tiene quién cuide a su hijo/s en el horario en que usted trabaja?*</MenuItem>
                <MenuItem key='Sí' value='Sí'>Sí</MenuItem>
                <MenuItem key='No' value='No'>No</MenuItem>
                <MenuItem key='No tengo' value='noTengo'>No tengo hijo/s</MenuItem>
            </SelectForm>

            <SelectForm name="accionJudicial" placeholder="¿En algún trabajo tuvo alguna dificultad que derivó en una acción judicial?" value={dataForm?.accionJudicial} onChange={(e) => setMessage(e.target)}>
                <MenuItem key='default' value='default' disabled>¿En algún trabajo anterior tuvo alguna dificultad la cuál derivó en una acción judicial?*</MenuItem>
                <MenuItem key='Sí' value='Sí'>Sí</MenuItem>
                <MenuItem key='No' value='No'>No</MenuItem>
            </SelectForm>

            <InputForm name="tiempoMotivo" type='text' value={dataForm?.tiempoMotivo} multiline rows={2}
                placeholder="Tiempo y motivo que estuvo sin trabajar desde el último empleo*"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="comoSeConsidera" type='text' value={dataForm?.comoSeConsidera} multiline rows={2}
                placeholder="¿Cómo se considera como empleado/a y compañero/a en un trabajo?*"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="trabajaOEstudia" type='text' value={dataForm?.trabajaOEstudia} multiline rows={2}
                placeholder="¿Se encuentra trabajando o estudiando? Por favor, indicar dónde, días y horarios*"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="RazonNoTrabajo" type='text' value={dataForm?.RazonNoTrabajo} multiline rows={2}
                placeholder="Indicar el motivo por el cuál no trabaja más en el último empleo*"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="EnfermedadOTratamiento" type='text' value={dataForm?.EnfermedadOTratamiento} multiline rows={2}
                placeholder="¿Alguna enfermedad que tenga o un tratamiento que esté realizando?"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="Medicamento" type='text' value={dataForm?.Medicamento} multiline rows={2}
                placeholder="¿Toma algún medicamento? Por favor, indiquelo"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="ExperienciaGastronomia" type='text' value={dataForm?.ExperienciaGastronomia} multiline rows={2}
                placeholder="Indique su experiencia en gastronomía"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="QueEsperaDelTrabajo" type='text' value={dataForm?.QueEsperaDelTrabajo} multiline rows={2}
                placeholder="¿Que es lo que espera de este trabajo?"
                onChange={(e) => { setMessage(e.target) }} />

            <InputForm name="renumeracionPretendida" type='number' value={dataForm?.renumeracionPretendida} placeholder="Indique su remuneración mensual pretendida*"
                onChange={(e) => { validate(/^\d+$/.test(e.target.value), e.target.name, 'La renumeración debe ser números', e.target.value); setMessage(e.target) }} />
            <p style={{ margin: 0, color: '#ec3237' }}>{errors?.renumeracionPretendida}</p>

            {/** Fin Formulario pre laboral */}

            <SelectForm name="sucursal" placeholder="Local de interés" value={dataForm?.sucursal} onChange={(e) => setMessage(e.target)}>
                <MenuItem key='default' value='default' disabled>Local de interés*</MenuItem>
                <MenuItem key='pontevedra' value='pontevedra'>Pontevedra</MenuItem>
                <MenuItem key='libertad' value='libertad'>Libertad</MenuItem>
                <MenuItem key='marcosPaz' value='marcosPaz'>Marcos Paz</MenuItem>
            </SelectForm>

            <InputForm name="message" type='text' value={dataForm?.message} placeholder="Mensaje adicional que quiera dejar" multiline rows={2} onChange={(e) => setMessage(e.target)} />

            <label for="cv" style={{ marginTop: '2%' }}>Adjuntar CV (.PDF, .doc, .docx)</label><br />
            <input
                name='cv'
                type="file"
                accept=".doc, .docx, .pdf"
                style={{ marginBottom: '2%' }}
                ref={cvRef}
            />

            <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_AUTH} onChange={() => { setCaptcha(true) }} style={{ marginTop: '2%' }} />

            <TermsAndConditions checked={checked} setChecked={setChecked} />

            <CustomButton variant="outlined" color="primary" type="submit" disabled={!captcha || !checkIfErrorIsEmpty(errors) || checkIfEmpty() || checked === false}>
                ENVIAR
            </CustomButton>
            {loading && <> <br /> <CircularProgress /></>}
            {statusForm === true ? <p style={{ margin: 0, color: 'green' }}>✔️ Enviado exitosamente</p> : statusForm === false && <p style={{ margin: 0, color: '#ec3237' }}>❌ Ocurrió un error</p>}
        </form>
    )
}

export default WorkWithUsForm
