import { Grid, makeStyles } from '@material-ui/core';
import useWindowDimensions from 'helpers/getDimensions';
import PostresImages from 'mock/PostresImage';
import React from 'react';

function PostresComponent() {
    const { width } = useWindowDimensions();
    const classes = useStyles();

    return (
        <>
            <div className={`${classes.carousel} ${width > 960 && classes.padding}`} style={width > 960 ? { minHeight: '100vh' } : {paddingBottom: '5vh'}}>
                <p style={{ fontFamily: '"Montserrat", "sans-serif"', fontWeight: 900, fontSize: '3em', padding: 0, margin: 0 }}>POSTRES</p>
                <p style={{ fontFamily: '"Montserrat", "sans-serif"', fontWeight: 900, fontSize: '3em', padding: 0, margin: 0, marginBottom: '1vh' }}>& BEBIDAS</p>
                <Grid container>
                    {PostresImages.map((x, i) => (
                        <Grid item xs={12} sm={12} md={4} key={i}>
                            <div className={classes.tarjetaContenido} style={{ backgroundImage: `url(${x.url})`, height: "40vh" }}>
                            </div>
                        </Grid>
                    ))
                    }
                </Grid >
            </div >
        </>
    )
}

const useStyles = makeStyles({
    carousel: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: '#ec3237',
        paddingTop: '10vh',
        color: 'white',

    },
    padding: {
        paddingLeft: '15vw',
        paddingRight: '15vw',
    },
    tarjetaContenido: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        margin: '0.8vh',
        backgroundSize: 'contain',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        borderRadius: '10%'
    },
    tarjetaTexto: {
        textAlign: "center",
        marginBottom: "4em",
        color: 'black'
    },
    tarjetaPrecio: {
        fontSize: "1.5em",
        fontWeight: 900,
        color: "#ec3237",
        marginTop: ".8em"
    }
});

export default PostresComponent
