import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import configurableVariables from 'configuration';
import useWindowDimensions from 'helpers/getDimensions';
import CarouselImages from 'mock/CarouselImages';
import CarouselImagesMobile from 'mock/CarouselImagesMobile';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Autoplay, Navigation } from 'swiper';
import 'swiper/components/navigation/navigation.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import Hidden from '@material-ui/core/Hidden';
import Modal from './Modal'

// Import Swiper styles
import 'swiper/swiper.scss';

SwiperCore.use([Autoplay, Navigation]);


function HomeComponent() {
    const classes = useStyles();
    const [isMobile, setIsMobile] = useState(false);
    const { width } = useWindowDimensions();
    const [images, setImages] = useState(CarouselImages);

    useEffect(() => {
        if (width < 960) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, [width])

    useEffect(() => {
        setImages(isMobile ? CarouselImagesMobile : CarouselImages)
    }, [isMobile])

    return (
        <div id="home">
            <Swiper loop autoplay={{ delay: configurableVariables.msInCarousel, disableOnInteraction: false }} navigation>
                {images?.map((i) => {
                    return (
                        <SwiperSlide key={i.id}>
                            <div className={classes.imgCarousel} style={{ backgroundImage: `url(${i.url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                <Grid container >
                                    <Hidden xsDown>
                                    <Grid item md={3}>
                                    </Grid>
                                    </Hidden>
                                    <Grid item md={7} xs={12}>
                                        <Button variant="contained" color="primary" style={{ marginTop: '40vh', marginLeft: '5vw', fontWeight: 800, fontSize: '1.2em' }} component={Link} to={'/Combos'}>VER COMBOS</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <Modal />
        </div>
    )
}

const useStyles = makeStyles({
    carousel: {
        height: '100vh',
    },
    imgCarousel: {
        height: '100vh',
        // width: "100%",
        // position: "relative",
        // left: '50vh'
    }
});

export default HomeComponent
