import { makeStyles } from '@material-ui/core';
import Footer from 'components/Footer/Footer';
import { LayoutContent } from 'components/Layout/LayoutContent';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/fondo-individuales.png';
import IconoCompleto from 'imgs/icons/Icono-C.png';
import IconoSimple from 'imgs/icons/Icono-S.png';
import MenuList from 'mock/MenuList';
import { useEffect, useState } from 'react';
import ComoPedir from '../ComoPedir';

function IndividualesPage() {
    const classes = useStyles();
    const [menuInfo, setMenuInfo] = useState({ firstTable: [], secondTable: [] })
    const { width } = useWindowDimensions();

    useEffect(() => {
        const middleIndex = Math.ceil(MenuList.length / 2)
        let copyOfMenu = [...MenuList];
        const firstTable = copyOfMenu.splice(0, middleIndex);
        const secondTable = copyOfMenu.splice(-middleIndex)
        setMenuInfo({ firstTable: firstTable, secondTable: secondTable });
    }, [])

    return (
        <LayoutContent menu={true}>
            <div className={classes.carousel} style={width >= 960 ? {backgroundImage: `url(${Background})`,backgroundSize: 'cover'} : {backgroundColor: 'white', paddingBottom: '5vh'}}>
                <div className="contenedor-precios">
                    <div>
                        <span className={classes.texto}>INDIVI-</span><br />
                        <span className={classes.texto}>DUALES</span>
                    </div>
                    <h3 style={{ fontFamily: '"Montserrat", "sans-serif"', fontWeight: 600, margin: 0 }}>SIMPLES O COMPLETOS</h3>
                    <div className="contenedor-tabla">
                        {Object.keys(menuInfo).map((keyName, i) => {
                            return (
                                <table className="tabla-precios" key={i}>
                                    {
                                        menuInfo[keyName].map((x, i) => {
                                            return (
                                                <tr className="fila-precios" key={i}>
                                                    <td className="tabla-td">{x.title}</td>
                                                    <td className="tabla-td"><img src={IconoSimple} alt="Icono Simples" title="Simples" /> {x.simplePrice}</td>
                                                    <td className="tabla-td">{x.completePrice !== "" && <img src={IconoCompleto} alt="Icono Completo" title="Completo" />} {x.completePrice}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </table>
                            )
                        })}
                    </div>
                </div>
            </div >
            <ComoPedir />
            <Footer />
        </LayoutContent>
    )
}

const useStyles = makeStyles({
    carousel: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '10vh',
        minHeight: '90vh',
    },
    texto: {
        color: '#ec3237',
        fontFamily: '"Montserrat", "sans-serif"',
        fontWeight: 900,
        fontSize: '4em',
        padding: 0,
        margin: 0,
    }
});

export default IndividualesPage
