import { Grid, makeStyles } from '@material-ui/core';
import useWindowDimensions from 'helpers/getDimensions';
import Background from 'imgs/Backgrounds/fondo-hablanos-home.png';
import ContactForm from './components/ContactForm';

function ContactoComponent() {
    const classes = useStyles();
    const { width } = useWindowDimensions();

    return (
        <Grid container className={width >= 960 ? classes.main : classes.mobileMain} id="contacto">
            <Grid item md={7} xs={12}>
            </Grid>
            <Grid item md={4} xs={12} >
                <div style={{ textAlign: width >= 960 ? 'left' : 'center' }}>
                    <h1 className={classes.title} style={{ color: '#ec3237', fontSize: width >= 960 ? '3em' : '2em' }}>¡ HABLANOS !</h1>
                    <h1 className={classes.title} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>Y CONTANOS TU</h1>
                    <h1 className={classes.title} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>EXPERIENCIA</h1>
                    <h1 className={classes.title} style={{ fontSize: width >= 960 ? '3em' : '2em' }}>—</h1>
                </div>
                <div style={{ textAlign: 'left' }}>
                    <ContactForm />
                </div>
            </Grid>
            <Grid item md={1} xs={12} >
            </Grid>
        </Grid >
    )
}

const useStyles = makeStyles({
    title: {
        fontFamily: '"Montserrat", "sans-serif"',
        fontWeight: 900,
        fontStyle: 'bold',
        color: "#474747",
        margin: 0,
        padding: 0
    },
    main: {
        padding: '10vh',
        backgroundImage: `url(${Background})`,
        backgroundSize: 'cover',
    },
    mobileMain: {
        padding: '5vw',
    }
});

export default ContactoComponent

