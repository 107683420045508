import { createTheme } from '@material-ui/core/styles';
import type
{
    Theme,
} from '@material-ui/core';

const theme: Theme = createTheme({
    palette: {
        primary: {
            main: "#EC3237",
        },
        secondary: {
            main: "#FFFFFF"
        }
    },
    typography:{
        "fontFamily": '"Montserrat", "sans-serif"'
    }
});


export default theme;