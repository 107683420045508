import Combo1 from 'imgs/Carrusel-Combos/Combo1.png';
import Combo2 from 'imgs/Carrusel-Combos/Combo2.png';
import Combo3 from 'imgs/Carrusel-Combos/Combo3.png';
import Combo4 from 'imgs/Carrusel-Combos/Combo4.png';
import Combo5 from 'imgs/Carrusel-Combos/Combo5.png';
import Combo6 from 'imgs/Carrusel-Combos/Combo6.png';
import Combo7 from 'imgs/Carrusel-Combos/Combo7.png';
import Combo8 from 'imgs/Carrusel-Combos/Combo8.png';
import Combo9 from 'imgs/Carrusel-Combos/Combo9.png';
import Combo10 from 'imgs/Carrusel-Combos/Combo10.png';
import Combo11 from 'imgs/Carrusel-Combos/Combo11.png';
import Combo12 from 'imgs/Carrusel-Combos/Combo12.png';
import Combo13 from 'imgs/Carrusel-Combos/Combo13.png';
import Combo14 from 'imgs/Carrusel-Combos/Combo14.png';
import Combo15 from 'imgs/Carrusel-Combos/Combo15.png';
import Combo16 from 'imgs/Carrusel-Combos/Combo16.png';

const ImagesCombos = [
    {
        id: 1,
        url: Combo1,
        title: "Superpancho",
        description: "Con papas y gaseosa.",
        price: "$4800"
    },
    {
        id: 2,
        url: Combo2,
        title: "Hamburguesa",
        description: "Con papas y gaseosa.",
        price: "$6200"
    }, {
        id: 3,
        url: Combo3,
        title: "Hamburguesa doble",
        description: "Con papas y gaseosa.",
        price: "$7600"
    }, {
        id: 4,
        url: Combo4,
        title: "Hamburguesa de pollo",
        description: "Con papas y gaseosa.",
        price: "$6200"
    }, {
        id: 5,
        url: Combo5,
        title: "Mega Hamburguesa",
        description: "Con papas y gaseosa.",
        price: "$7400"
    }, {
        id: 6,
        url: Combo6,
        title: "Nuggets de pollo",
        description: "Con papas y gaseosa.",
        price: "$7400"
    }, {
        id: 7,
        url: Combo7,
        title: "Milanesa de carne",
        description: "Con papas y gaseosa.",
        price: "$8000"
    }, {
        id: 8,
        url: Combo8,
        title: "Milanesa de pollo",
        description: "Con papas y gaseosa.",
        price: "$8000"
    }, {
        id: 9,
        url: Combo9,
        title: "Pollo grill",
        description: "Con papas y gaseosa.",
        price: "$8000"
    }, {
        id: 10,
        url: Combo10,
        title: "Lomito",
        description: "Con papas y gaseosa.",
        price: "$8800"
    }, {
        id: 11,
        url: Combo11,
        title: "Mega doble",
        description: "Con papas y gaseosa.",
        price: "$8700"
    }, {
        id: 12,
        url: Combo12,
        title: "Hamburguesa de pollo doble",
        description: "Con papas y gaseosa.",
        price: "$7600"
    }, {
        id: 13,
        url: Combo13,
        title: "Hamburguesa triple",
        description: "Con papas y gaseosa.",
        price: "$9100"
    }, {
        id: 14,
        url: Combo14,
        title: "Mega triple",
        description: "Con papas y gaseosa.",
        price: "$9900"
    }, {
        id: 15,
        url: Combo15,
        title: "Gourmet",
        description: "Con papas y gaseosa.",
        price: "$8100"
    }, {
        id: 16,
        url: Combo16,
        title: "Gourmet doble",
        description: "Con papas y gaseosa.",
        price: "$9700"
    },
];

export default ImagesCombos;